import { useContext } from "react";
import { OverviewContext } from "./overview-provider";

/**
 * Custom hook to access the overview context.
 * @returns {Object} The overview context object.
 */
const useOverviewContext = () => {
  const overviewContext = useContext(OverviewContext);
  return overviewContext;
};

export default useOverviewContext;
