import React from "react";
import { Field, useFormikContext } from "formik";
import PropTypes from "prop-types";
import { gql, useQuery } from "@apollo/client";
import { FormattedMessage } from "react-intl";
import Select from "react-select";

const TaxonomyFilter = ({ item }) => {
  const { setFieldValue, handleBlur, values } = useFormikContext();
  const TAXONOMY_QUERY = gql`
    query TaxonomyQuery($vid: String = "tags") {
      entityQuery(
        entityType: TAXONOMY_TERM
        filter: { conditions: [{ field: "vid", value: [$vid] }] }
        sort: { field: "weight", direction: ASC }
      ) {
        items {
          label
          id
        }
      }
    }
  `;
  
  // Get taxonomy terms.
  const { loading, error, data } = useQuery(TAXONOMY_QUERY, {
    variables: {
      vid: item.options.vid,
    },
  });

  if (loading) {
    return null;
  }

  if (error) {
    return (
      <div className="container">
        <div className="row">
          <div className="col-16 col-md-8 offset-md-1">
            <h3>
              <FormattedMessage id="error.general" />
            </h3>
            <pre>{JSON.stringify(error, null, 2)}</pre>
          </div>
        </div>
      </div>
    );
  }

  const handleChange = (newValue, actionMeta) => {
    if (item.options.expose.multiple) {
      if (actionMeta.action === "remove-value") {
        setFieldValue(
          item.options.id,
          values[item.options.id].filter(
            (value) => actionMeta.removedValue.value !== value
          )
        );
      }

      if (actionMeta.action === "select-option") {
        setFieldValue(item.options.id, [
          ...newValue.map((_item) => _item.value),
        ]);
      }

      if (actionMeta.action === "clear") {
        setFieldValue(item.options.id, []);
      }
    } else {
      setFieldValue(item.options.id, newValue.value);
    }
  };

  const terms = data.entityQuery.items.filter((item) => !!item?.label);

  const selectField = () => (
    <Select
      options={terms.map((term) => ({
        label: term.label,
        value: term.id,
      }))}
      isMulti={item.options.expose.multiple}
      name={item.options.id}
      value={
        item.options.expose.multiple
          ? values[item.options.id].map((item) => {
              const selectedOption = terms.find((term) => item === term.id);
              return { label: selectedOption.label, value: selectedOption.id };
            })
          : terms.find((term) => term.id === values[item.options.id])
      }
      onChange={(newValue, actionMeta) => {
        handleChange(newValue, actionMeta);
      }}
      onBlur={handleBlur}
    />
  );

  return <Field name={item.options.id} component={selectField} />;
};

TaxonomyFilter.propTypes = {
  item: PropTypes.shape({
    label: PropTypes.string,
    options: PropTypes.shape({
      id: PropTypes.string,
      vid: PropTypes.string,
      expose: PropTypes.shape({
        label: PropTypes.string,
        placeholder: PropTypes.string,
        multiple: PropTypes.bool,
      }),
    }),
  }),
};

export default TaxonomyFilter;
