import { useLockBodyScroll } from 'react-use';

/**
 * Locks the body scroll on mount.
 * @returns {null}
 */
const LockBodyScroll = () => {
    useLockBodyScroll();
    return null;
}

export default LockBodyScroll