import React from "react";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/client";
import SubmissionsQuery from "./submissions.graphql";
import toast from "@general-components/ui/toast";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import moment from "moment";
import _ from "lodash";

const CardSubmissionSkeleton = () => (
  <div className="list-group list-group-flush">
    <a className="list-group-item placeholder-glow">
      <div className="d-flex justify-content-between align-items-center">
        <span className="placeholder w-25"></span>
        <span className="placeholder col-3"></span>
      </div>
    </a>

    <a className="list-group-item placeholder-glow">
      <div className="d-flex justify-content-between align-items-center">
        <span className="placeholder w-50"></span>
        <span className="placeholder col-3"></span>
      </div>
    </a>

    <a className="list-group-item placeholder-glow">
      <div className="d-flex justify-content-between align-items-center">
        <span className="placeholder col-6"></span>
        <span className="placeholder col-3"></span>
      </div>
    </a>
  </div>
);

const CardSubmissions = ({ view }) => {
  const { data, loading, error } = useQuery(SubmissionsQuery, {
    variables: {
      view,
    },
    fetchPolicy: "network-only",
  });

  if (error) {
    toast({
      message: "Fehler beim Laden der Daten [submission_query].",
      type: "error",
    });
    return <div>Error: {error.message}</div>;
  }

  // extract submissions from data.
  let submissions = data?.entityById?.executable?.execute?.rows || [];

  // parse encoded data and store as object in submissions.
  submissions = submissions.map((submission) => ({
    ...submission,
    data: JSON.parse(submission.encodedData),
  }));

  console.log(submissions);

  // group submissions by node ID.
  submissions = _.groupBy(submissions, "data.nid");

  // flatten submissions to array.
  // only the first (latest) submission of each group is displayed.
  submissions = Object.values(submissions).map((group) => group[0]);

  return (
    <div className="card">
      <div className="card-header">
        <h3>
          <FormattedMessage id={`submissions.header.${view}`} />
        </h3>
      </div>
      {loading && <CardSubmissionSkeleton />}
      {!loading && !error && submissions?.length && (
        <div className="list-group list-group-flush">
          {submissions.map((submission) => (
            <Link
              className="list-group-item list-group-item-action"
              to={`/intern/submit-event/${submission.uuid}`}
              key={submission.uuid}
            >
              <div className="d-flex justify-content-between align-items-center">
                <span>{submission.data.title}</span>
                <small>
                  Eingereicht am{" "}
                  {moment(submission.created)
                    .local()
                    .format("dd, DD.MM.YY, HH:mm")}
                </small>
              </div>
            </Link>
          ))}
        </div>
      )}
      {!loading && !error && !submissions?.length && (
        <div className="card-body">Keine Einträge vorhanden.</div>
      )}
      <div className="card-footer">
        <Link className="card-link" to="/intern/submit-event">
          Termin Einreichen
        </Link>
      </div>
    </div>
  );
};

CardSubmissions.propTypes = {
  view: PropTypes.oneOf(["event_submissions"]).isRequired,
};

export default CardSubmissions;
