import React from "react";
import { FormattedMessage } from "react-intl";

const Error503 = () => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-16">
          <h1 className="text-center">503</h1>
          <p className="text-center">
            <FormattedMessage id="error.network_error" />
          </p>
        </div>
      </div>
    </div>
  );
};

export default Error503;
