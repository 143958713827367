import React from "react";
import PropTypes from "prop-types";
import RichText from "@general-components/rich-text/rich-text";

const ParagraphSchedule = ({ content }) => {
  return (
    <section className="paragraph paragraph-schedule">
      {content.fieldElements.map((element) => (
        <div className="item-wrapper" key={element.id}>
          <div className="container">
            <div className="row">
              <div className="col-16 col-md-5 col-lg-3">
                <h3>{element.fieldLabel}</h3>
              </div>
              <div className="col-16 col-md-11 col-lg-13">
                <p>
                  <span className="headline-md">{element.fieldTitle}</span>
                </p>
                <RichText>{element.fieldText}</RichText>
              </div>
            </div>
          </div>
        </div>
      ))}
    </section>
  );
};

ParagraphSchedule.propTypes = {
  content: PropTypes.shape({
    fieldElements: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        fieldTitle: PropTypes.string,
        fieldText: PropTypes.string,
      })
    ),
  }),
};

export default ParagraphSchedule;
