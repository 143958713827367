import { useLoaderData } from "react-router-dom";
import { useCanonicalOrRedirect } from "./use-canonical-or-redirect";
import { useI18n } from "../i18n/use-i18n";

const useNodeQuery = () => {
  /**
   * Hook to perform GraphQL query (with help of redux store)
   */
  const data = useLoaderData();

  const route = useCanonicalOrRedirect(data);
  const entity = route?.entity;

  useI18n(route?.languageSwitchLinks);

  return { entity };
};

export default useNodeQuery;
