import React from "react";

import AppLayout from "./app";
import Event from "@nodes/event/event";
import Landingpage from "@nodes/landingpage/landingpage";
import News from "@nodes/news/news";
import Person from "@nodes/person/person";
import PageDashboard from "./intern/pages/page-dashboard";
import PageLogin from "./intern/pages/page-login";
import InternLayout from "./intern/pages/layout";
import PageForgotPassword from "./intern/pages/page-forgot-password";
import PageResetPassword from "./intern/pages/page-reset-password";
import PageSubmitEvent, { pageSubmitEventLoader } from "./intern/pages/page-submit-event";
import RouteError from "./system/route-error";
import { nodeLoader } from "@nodes/loader";

const routes = (client) => ([
  {
    path: "/",
    element: <AppLayout />,
    children: [
      {
        errorElement: <RouteError />,
        children: [
          {
            path: ":language?/:alias",
            element: <Landingpage />,
            loader: async ({ request }) => {
              return await nodeLoader(client, request, "landingpage");
            },
            errorElement: <RouteError />,
          },
          {
            path: ":language?/news/:alias",
            element: <News />,
            loader: async ({ request }) => {
              return await nodeLoader(client, request, "event");
            },
            errorElement: <RouteError />,
          },
          {
            path: ":language?/veranstaltung/:alias",
            element: <Event />,
            loader: async ({ request }) => {
              return await nodeLoader(client, request, "event");
            },
            errorElement: <RouteError />,
          },
          {
            path: ":language?/person/:alias",
            element: <Person />,
            loader: async ({ request }) => {
              return await nodeLoader(client, request, "person");
            },
            errorElement: <RouteError />,
          },
          {
            path: ":language?/intern",
            element: <InternLayout />,
            errorElement: <RouteError />,
            children: [
              {
                path: "login",
                element: <PageLogin />,
              },
              {
                path: "forgot-password",
                element: <PageForgotPassword />,
              },
              {
                path: "reset-password",
                element: <PageResetPassword />,
              },
              {
                path: "dashboard",
                element: <PageDashboard />,
              },
              {
                path: "submit-event/:eventSubmissionUuid?",
                element: <PageSubmitEvent />,
                loader: async ({ params }) => {
                  return await pageSubmitEventLoader(client, params);
                }
              },
              {
                path: ":alias",
                element: <Landingpage />,
                loader: async ({ request }) => {
                  return await nodeLoader(client, request, "landingpage");
                },
              },
            ],
          },
        ],
      },
    ],
  },
]);

export default routes;
