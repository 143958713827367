import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { Close } from "@carbon/icons-react";
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from "body-scroll-lock";

import StoryTeaserContent from "./story-teaser-content";
import { activeCloudDetailAction } from "../../cloud-view-action";
import { ContentCloudNodePropTypes } from "../../paragraph-content-cloud";

const StoryTeaser = ({ content, index }) => {
  /**
   * 0 - 10
   * @type {number}
   */
  const randomNumber = Math.floor(Math.random() * 11);

  const [hover, setHover] = useState(false);
  const [scrollTargetElement, setScrollTargetElement] = useState(null);

  const activeCloudDetail = useSelector(
    (reduxStore) => reduxStore.contentCloud.activeCloudDetail
  );
  const dispatch = useDispatch();

  const storyElement = useRef();
  const contentWrapper = useRef();

  const waitForElm = (selector) => {
    // From:
    // https://stackoverflow.com/questions/5525071/how-to-wait-until-an-element-exists
    return new Promise((resolve) => {
      if (document.querySelector(selector)) {
        return resolve(document.querySelector(selector));
      }

      const observer = new MutationObserver(() => {
        if (document.querySelector(selector)) {
          resolve(document.querySelector(selector));
          observer.disconnect();
        }
      });

      observer.observe(document.body, {
        childList: true,
        subtree: true,
      });
    });
  };

  const showDetail = (content) => {
    dispatch(activeCloudDetailAction(content));

    // Move active teaser to detail-view
    let parent = storyElement.current.closest(".cloud-teaser"),
      teaserPosition = storyElement.current.getBoundingClientRect();

    console.log(storyElement.current.getBoundingClientRect());
    document.body.style.setProperty(
      "--active-teaser-left",
      (parent.getBoundingClientRect().left -
        parent.clientWidth / 2 -
        parent.getAttribute("data-col-id") * 16) *
        -1 +
        "px"
    );
    document.body.style.setProperty(
      "--active-teaser-top",
      -teaserPosition.top + teaserPosition.height / 3 + "px"
    );

    // Block Scrolling when Detail is open
    waitForElm(".cloud-detail-content").then((elm) => {
      setScrollTargetElement(elm);
      disableBodyScroll(elm);
    });
  };

  const closeDetail = () => {
    enableBodyScroll(scrollTargetElement);
    dispatch(activeCloudDetailAction(null));
    document.body.style.setProperty("--active-teaser-left", 0);
  };

  useEffect(() => {
    return clearAllBodyScrollLocks;
  }, []);

  const cloudContentClasses = classNames({
    "cloud-teaser col-lg-2 col-sm-6 col-6": true,
    active: activeCloudDetail?.entityId === content.entityId,
  });

  if (content != null) {
    return (
      <div
        ref={storyElement}
        className={cloudContentClasses}
        data-col-id={index + 1}
      >
        <div
          className="cloud-detail-go-back"
          onClick={() => closeDetail(content)}
        >
          <Close size={32} color="black" />
        </div>
        <div
          className="teaser-wrapper"
          onClick={() => showDetail(content)}
          style={{
            top: `${randomNumber * 4.5}vh`,
            left:
              index === 0 ? `${randomNumber}vw` : `-${randomNumber * 1.5}vw`,
          }}
        >
          <StoryTeaserContent
            inView={index !== 0 && index !== 4}
            content={content}
            hover={hover}
            setHoverState={() => setHover((prev) => !prev)}
            right={index === 4}
            left={index === 0}
            contentWrapper={contentWrapper}
          />
        </div>
      </div>
    );
  }

  return false;
};

StoryTeaser.propTypes = {
  content: PropTypes.shape(ContentCloudNodePropTypes),
  index: PropTypes.number.isRequired,
};

export default StoryTeaser;
