import React from "react";
import PropTypes from "prop-types";
import RelatedQuery from "./related.graphql";
import { useQuery } from "@apollo/client";
import TeaserBase from "@teasers/teaser-base";
import { FormattedMessage } from "react-intl";
import ErrorBoundary from "@js/error-boundary";

const Related = ({ nodeContent, contentType }) => {
  const { data, loading, error } = useQuery(RelatedQuery, {
    variables: {
      contentType,
      skipNid: nodeContent?.id,
      tags: nodeContent?.fieldTags?.map((tag) => tag.id) || [],
    },
  });

  const nodes = data?.entityById?.executable?.execute?.rows || [];

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;
  if (nodes.length === 0) return null;

  return (
    <section className="related-content">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h2>
              <FormattedMessage id="related.headline" />
            </h2>
          </div>
        </div>
        <div className="row">
          <ErrorBoundary>
            {nodes.map((node) => (
              <TeaserBase item={node} key={node.id} />
            ))}
          </ErrorBoundary>
        </div>
      </div>
    </section>
  );
};

Related.propTypes = {
  nodeContent: PropTypes.object.isRequired,
  contentType: PropTypes.arrayOf(
    PropTypes.oneOf(["article", "event", "news", "person", "project"])
  ).isRequired,
};

export default Related;
