import React from "react";
import useAuthentication from "../use-authentication";
import CardUser from "./components/card-user";
import CardMemberActions from "./components/card-member-actions";
import CardSubmissions from "./components/card-submissions";

const PageDashboard = () => {
  const { auth } = useAuthentication();
  const currentUser = auth?.currentUser;

  return (
    <article className="intern intern-dashboard">
      <div className="container">
        <div className="row">
          <div className="col-16">
            <div className="dashboard-head">
              <h1>Hallo {currentUser?.name}</h1>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-16 col-lg-4">
            <CardMemberActions />
            <CardUser />
          </div>
          <div className="col-16 col-lg-12">
            <CardSubmissions view="event_submissions" />
          </div>
        </div>
      </div>
    </article>
  );
};

PageDashboard.propTypes = {};

export default PageDashboard;
