import React, { cloneElement } from "react";
import PropTypes from "prop-types";
import { hyphenateSync as hyphenateDE } from "hyphen/de";
import { hyphenateSync as hyphenateEN } from "hyphen/en";
import { useSelector } from "react-redux";

/**
 * Hyphenate a string or HTML-string based on the current language.
 */
const Hyphenate = ({
  children,
  htmlString = false,
  wrapper = <div />,
  minWordLength = 5,
  ...props
}) => {
  const currentLanguage = useSelector((state) => state.i18n.language);

  const hyphenateOptions = {
    minWordLength,
  };

  // Hyphenate the string based on the current language.
  const hyphenatedString = (() => {
    switch (currentLanguage) {
      case "en":
        return hyphenateEN(children, hyphenateOptions);
      case "de":
      case "und":
      default:
        return hyphenateDE(children, hyphenateOptions);
    }
  })();

  // Return inside a wrapper if it is an HTML string.
  // Otherwise return the string itself.
  if (htmlString) {
    return cloneElement(wrapper, {
      ...props,
      ...wrapper.props,
      dangerouslySetInnerHTML: { __html: hyphenatedString },
    });
  } else {
    return hyphenatedString;
  }
};

Hyphenate.propTypes = {
  children: PropTypes.string.isRequired,
  /**
   * Set to true, if the string is an HTML string.
   * This will render the hyphenated string as HTML inside a div.
   * All props wll be passed to the div. It is also possible to pass a custom wrapper element.
   *
   * @default false
   */
  htmlString: PropTypes.bool,
  /**
   * Set a JSX wrapper element to render the hyphenated string as HTML inside your custom element.
   *
   * @example <Hyphenate htmlString={true} wrapper={<span />}>{string}</Hyphenate>
   * @default <div />
   */
  wrapper: PropTypes.element,
  /**
   * Minimal word length (in chars) to hyphenate.
   *
   * @default 5
   */
  minWordLength: PropTypes.number,
};

export default Hyphenate;
