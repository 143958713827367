import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Hyphenate from "@general-components/hyphenate/hyphenate";

const RichText = ({ children, wrapper = <div />, className, ...props }) => {
  const classes = classNames({
    text: true,
    [className]: className,
  });

  return (
    <Hyphenate
      className={classes}
      wrapper={wrapper}
      htmlString={true}
      {...props}
    >
      {children}
    </Hyphenate>
  );
};

RichText.propTypes = {
  children: PropTypes.string.isRequired,
  /**
   * Set a JSX wrapper element to render the hyphenated string as HTML inside your custom element.
   *
   * @example <Hyphenate htmlString={true} wrapper={<span />}>{string}</Hyphenate>
   * @default <div />
   */
  wrapper: PropTypes.element,
  /**
   * Additional classes for the text.
   * Adds "text" class by default.
   */
  className: PropTypes.string,
};

export default RichText;
