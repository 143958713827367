import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import TeaserBase from "../../../teaser-base/teaser-base";

// Overview related components
import OverviewProvider from "./store/overview-provider";
import useOverviewContext from "./store/use-overview-context";
import Filters from "./components/filters";

import NoResults from "./components/system/no-results";
import Loading from "./components/system/loading";
import Error from "./components/system/error";
import Pagination from "./components/system/pagination";
import EndlessScroll from "./components/system/endless-scroll";

/**
 * Renders the Overview module.
 * This component is wrapped by the overview context provider at the end of
 * this file. The whole state of this module is handled by the overview context
 * located in ./store.
 */
const Overview = () => {
  // Get relevant values from the OverviewContext.
  const { contentType, nodes, error } = useOverviewContext();

  return (
    <section
      className={classNames({
        "paragraph paragraph-extended-teaser-view": true,
      })}
    >
      <div className="container">
        <Filters />

        {!!nodes?.length && !error && (
          <>
            {/* Teasers */}
            <div className="row">
              {nodes.map((node) => (
                <div
                  key={node.id}
                  className={classNames({
                    "col-16": contentType !== "event",
                  })}
                  style={{ "margin-bottom": "200px" }}
                >
                  <TeaserBase item={node} />
                </div>
              ))}
            </div>
          </>
        )}

        <NoResults />
        <Loading />
        <Error />
        
        {/* Pagination */}
        {/* <Pagination /> */}

        {/* Endless scroll (uncomment and import to use it) */}
        <EndlessScroll rootMargin={3000} />
      </div>
    </section>
  );
};

// Wrap the Overview component with the OverviewProvider.
const ParagraphOverview = ({ content }) => {
  return (
    <OverviewProvider content={content}>
      <Overview />
    </OverviewProvider>
  );
};

ParagraphOverview.propTypes = {
  content: PropTypes.shape({
    fieldContentType: PropTypes.oneOf(["news", "event", "project", "person"]),
  }),
};

export default ParagraphOverview;
