import Error from "@system/error";
import Error401 from "@system/error-401";
import Error403 from "@system/error-403";
import Error404 from "@system/error-404";
import Error503 from "@system/error-503";
import React from "react";
import { useRouteError } from "react-router-dom";

const RouteError = () => {
  const error = useRouteError();
  console.error(error);

  if (error.status === 404) {
    return <Error404 />;
  }

  if (error.status === 403) {
    return <Error403 />;
  }
  
  if (error.status === 401) {
    return <Error401 />;
  }

  if (error.status === 503) {
    return <Error503 />;
  }

  return <Error error={error} />;
};

export default RouteError;
