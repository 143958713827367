import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Link, Navigate } from "react-router-dom";
import ExecutionEnvironment from "exenv";
import { tokenExpired } from "../util";
import useAuthentication from "../use-authentication";
import { FormattedMessage } from "react-intl";

const FormLogin = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const { login } = useAuthentication();

  const loginForm = useRef();

  const auth = useSelector((reduxStore) => reduxStore.auth);

  const submitHandler = async (e) => {
    e.preventDefault();
    setError(null);

    const loginData = new FormData(loginForm.current);

    const email = loginData.get("email");
    const password = loginData.get("password");

    if (email === "" || password === "") {
      return setError("Bitte füllen Sie alle Felder aus.");
    }

    setLoading(true);
    const response = await login(email, password);
    if (response.status !== 200) {
      setError("Login-Daten sind nicht korrekt.");
    }
    setLoading(false);
  };

  if (
    auth.userLoginData.logout_token &&
    ExecutionEnvironment.canUseDOM &&
    auth.userLoginData.temp_pass
  ) {
    return <Navigate to="/intern/reset-pass/temp" />;
  }

  if (
    auth.userLoginData.logout_token &&
    !tokenExpired(
      auth.userLoginData.access_token,
      auth.userLoginData.jwt.exp
    ) &&
    ExecutionEnvironment.canUseDOM
  ) {
    return <Navigate to={"/intern/dashboard"} />;
  }

  return (
    <div className="form-login">
      <form onSubmit={submitHandler} ref={loginForm}>
        <fieldset className="form-group">
          <label htmlFor="email">
            <FormattedMessage id="authentication.username" />
          </label>
          <input className="form-control" type="text" name="email" id="email" />
        </fieldset>
        <fieldset className="form-group">
          <label htmlFor="password">
            <FormattedMessage id="authentication.password" />
          </label>
          <input
            className="form-control"
            type="password"
            name="password"
            id="password"
          />
        </fieldset>
        {error && <div className="error-msg">{error}</div>}
        <button className="btn btn-primary" type="submit">
          {loading && (
            <span
              className="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            />
          )}
          Login
        </button>
      </form>
      <div className="help-links">
        <Link to="/intern/forgot-password">
          <FormattedMessage id="authentication.forgot_password" />
        </Link>
        <Link to="/intern/register">
          <FormattedMessage id="authentication.register" />
        </Link>
      </div>
    </div>
  );
};

FormLogin.propTypes = {};

export default FormLogin;
