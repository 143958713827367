import React from "react";
import FormLogin from "../components/form-login";

const PageLogin = () => {
  return (
    <article>
      <div className="container">
        <div className="row">
          <div className="col-16 col-sm-12 offset-sm-2 col-md-8 offset-md-4 col-lg-6 offset-lg-5">
            <h1>Login</h1>
          </div>
        </div>
        <div className="row">
          <div className="col-16 col-sm-12 offset-sm-2 col-md-8 offset-md-4 col-lg-6 offset-lg-5">
            <FormLogin />
          </div>
        </div>
      </div>
    </article>
  );
};

export default PageLogin;
