import { Menu } from "@general-components/navigation/menu";
import React from "react";

const CardMemberActions = () => {
  return (
    <div className="card card-member-actions">
      <Menu
        menuName="MEMBER_ACTIONS"
        ulClassName="list-group list-group-flush"
        liClassName="list-group-item"
      />
    </div>
  );
};

export default CardMemberActions;
