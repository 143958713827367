import React from "react";
import FormResetPassword from "../components/form-reset-password";
import useAuthentication from "../use-authentication";
import { Navigate } from "react-router-dom";
import ExecutionEnvironment from "exenv";

const PageResetPassword = () => {
  const { auth } = useAuthentication();

  // Redirect to dashboard if user is already logged in.
  if (auth.userLoginData.logout_token && ExecutionEnvironment.canUseDOM) {
    return <Navigate to="/intern/dashboard" />;
  }

  return (
    <article>
      <div className="container">
        <div className="row">
          <div className="col-16 col-sm-12 offset-sm-2 col-md-8 offset-md-4 col-lg-6 offset-lg-5">
            <h1>Passwort zurücksetzen</h1>
          </div>
        </div>
        <div className="row">
          <div className="col-16 col-sm-12 offset-sm-2 col-md-8 offset-md-4 col-lg-6 offset-lg-5">
            <FormResetPassword />
          </div>
        </div>
      </div>
    </article>
  );
};

export default PageResetPassword;
