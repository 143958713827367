import React from "react";
import PropTypes from "prop-types";
import Image from "../../image/image";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import EditButton from "../../../backend/edit-button";
import { pagerFullPageContextAction } from "../../../app-actions";
import FieldTime from "../../date-time/field-time";
import { teaserNewsPropTypes } from "./teaser-news";
import Tags from "../../tags/tags";

const TeaserNewsTimeline = ({ item, pagerFullPage = false }) => {
  const adminApp = useSelector((reduxStore) => reduxStore.appStore.adminApp);
  const dispatch = useDispatch();
  const location = useLocation();

  return (
    <article className="node node-teaser teaser-news teaser-news-timeline">
      <EditButton
        adminApp={adminApp}
        entityId={item.id}
        destinationRoute={location.pathname}
      />
      <Link
        onClick={() => dispatch(pagerFullPageContextAction(pagerFullPage))}
        className="flex-wrap"
        to={item.url?.path || item.path?.alias}
      >
        {item.fieldImage && (
          <Image
            data={item.fieldImage.fieldMediaImage}
            nodeTitle={item.title}
          />
        )}

        <FieldTime
          timestamp={true}
          date={
            item.publishedAtRawField?.first.value ||
            item.publishedAtRawField?.first.publishedAtOrNow
          }
          format={"DD.MM.YYYY"}
        />

        <h3>{item.title}</h3>

        {item.fieldText && (
          <div
            className="teaser text"
            dangerouslySetInnerHTML={{
              __html: item.fieldText,
            }}
          />
        )}

        <Tags tags={item.fieldTags} />
      </Link>
    </article>
  );
};

TeaserNewsTimeline.propTypes = {
  item: teaserNewsPropTypes,
  pagerFullPage: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
    .isRequired,
};

export default TeaserNewsTimeline;
