import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import Image from "../../image/image";
import TeaserPersonOverlay from "./teaser-person-overlay";
import EditButton from "../../../backend/edit-button";
import { teaserPersonPropTypes } from "./teaser-person";
import LockBodyScroll from "@general-components/lock-body-scroll";

/**
 * @todo Rename. This is the person teaser with modal on click.
 * @todo Implement viewMode property for all invocations.
 */
const TeaserPersonOverview = ({ item }) => {
  const [isOpen, setIsOpen] = useState(false);

  const adminApp = useSelector((reduxStore) => reduxStore.appStore.adminApp);

  const location = useLocation();

  return (
    <>
      <article
        className="node node-teaser teaser-person teaser-person-overview"
        data-testid="teaser-person"
      >
        <EditButton
          adminApp={adminApp}
          entityId={item.id}
          destinationRoute={location.pathname}
        />
        <div className="flex-wrap" onClick={() => setIsOpen(prev => !prev)}>
          {item.fieldImageRawField?.first && (
            <Image
              data={
                item.fieldImageRawField.first.entity.fieldMediaImage
              }
              nodeTitle={item.title}
            />
          )}
          <h3>{item.title}</h3>

          <div className="role">{item.fieldRole}</div>

          {item.fieldEmail && (
            <a className="mail" href={`mailto:${item.fieldEmail}`}>
              {item.fieldEmail}
            </a>
          )}

          {item.fieldPhone && (
            <a
              className="phone"
              href={`tel:${item.fieldPhone
                .replace(/ /g, "")
                .replace(/\([\s\S]*?\)/g, "")}`}
            >
              {item.fieldPhone}
            </a>
          )}
        </div>
      </article>
      {isOpen && (
        <div className="person-overlay-bg">
          <div className="person-overlay">
            <div className="container">
              <LockBodyScroll />
              <TeaserPersonOverlay
                item={item}
                toggleOverlay={() => setIsOpen(prev => !prev)}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

TeaserPersonOverview.propTypes = {
  item: teaserPersonPropTypes,
};

export default TeaserPersonOverview;
