import React from "react";
import classNames from "classnames";
import { useSelector } from "react-redux";
import Image from "../../image/image";
import moment from "moment";
import { Link, useLocation } from "react-router-dom";

import EditButton from "../../../backend/edit-button";
import { teaserEventPropTypes } from "./teaser-event";

/**
 * @todo use <FieldDate>
 */
const TeaserEventTeaserlist = ({ item }) => {
  const adminApp = useSelector((reduxStore) => reduxStore.appStore.adminApp);
  const location = useLocation();

  return (
    <article
      className={`node node-${item.id} node-teaser teaser-event teaser-list`}
    >
      <EditButton
        adminApp={adminApp}
        entityId={item.id}
        destinationRoute={location.pathname}
      />

      <Link
        className="flex-wrap"
        to={item.url ? item.url.path : item.path.alias}
      >
        <div
          className={classNames({
            row: true,
          })}
        >
          <div
            className={classNames({
              "col-16": true,
            })}
          >
            {item.fieldImage && (
              <Image
                data={item.fieldImage.fieldMediaImage}
                nodeTitle={item.title}
              />
            )}
          </div>

          <div
            className={classNames({
              "col-16": true,
            })}
          >
            <div className="row">
              <div className="col-16">
                <div className="meta-date top-line">
                  <span>
                    {moment.utc(item.fieldDate).local().format("ddd")}
                  </span>
                  {moment.utc(item.fieldDate.value).local().format("HH:mm") !==
                    "00:00" && (
                    <span>
                      {moment.utc(item.fieldDate.value).local().format("HH:mm")}{" "}
                      Uhr
                    </span>
                  )}
                  <span>
                    {moment
                      .utc(item.fieldDate.value)
                      .local()
                      .format("DD.MM.YYYY")}
                  </span>
                  <div className="location">{item.fieldOrt}</div>
                  {item.fieldDate.endValue && !item.fieldDauerAusblenden && (
                    <div className="duration">
                      Dauer{" "}
                      {Math.round(
                        moment
                          .duration(
                            moment
                              .utc(item.fieldDate.endValue)
                              .local()
                              .diff(moment.utc(item.fieldDate.value).local())
                          )
                          .asHours()
                      )}{" "}
                      Std.
                    </div>
                  )}
                </div>
              </div>
              <div className="col-16">
                {item.fieldTags.length > 0 && (
                  <span className="top-line">{item.fieldTags[0].name}</span>
                )}
                <h3>{item.title}</h3>
                <div
                  className="text teaser-text d-none d-lg-block"
                  dangerouslySetInnerHTML={{
                    __html: `${item.fieldText}`,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </Link>
    </article>
  );
};

TeaserEventTeaserlist.propTypes = {
  item: teaserEventPropTypes,
};

export default TeaserEventTeaserlist;
