import useAuthentication from "@js/intern/use-authentication";
import React from "react";
import CurrentUserQuery from "../../current-user-query.graphql";
import { useQuery } from "@apollo/client";
import { authenticationUserDataAction } from "@js/intern/redux-authentication";
import { useDispatch } from "react-redux";

const CardUser = () => {
  const { auth } = useAuthentication();
  const dispatch = useDispatch();

  const { currentUser } = auth;

  useQuery(CurrentUserQuery, {
    onCompleted: (data) => {
      dispatch(authenticationUserDataAction(data?.currentUser));
    },
  });

  return (
    <div className="card card-user">
      <div className="card-header">
        <h3>{currentUser?.name}</h3>
      </div>
      <div className="card-body">
        {(() => {
          if (currentUser?.fieldAddress) {
            const addr = currentUser.fieldAddress;
            return (
              <p className="address">
                {addr.addressLine1 && (
                  <span className="address-item address-line-1">
                    {addr.addressLine1}
                  </span>
                )}
                {addr.addressLine2 && (
                  <span className="address-item address-line-2">
                    {addr.addressLine2}
                  </span>
                )}
                {addr.postalCode && (
                  <span className="address-item postal-code">
                    {addr.postalCode}
                  </span>
                )}
                {addr.locality && (
                  <span className="address-item locality">{addr.locality}</span>
                )}
              </p>
            );
          }
        })()}
        <p>{currentUser?.fieldPhone}</p>
        <p>{currentUser?.mail}</p>
      </div>
    </div>
  );
};

export default CardUser;
