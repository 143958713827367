import React from "react";
import PropTypes from "prop-types";
import ChatBot from "react-simple-chatbot";
import ChooseAStyle from "./choose-a-style";
import Video from "./video";
import Links from "./links";
import Audio from "./audio";
import Image from "./image";
import eforkBotConfigQuery from "./eforkbot-config-query.graphql";
import { useQuery } from "@apollo/client";
import LoadingIndicator from "@general-components/loading-indicator";

const Eforkbot = () => {
  const { data, loading, error } = useQuery(eforkBotConfigQuery, {
    variables: { name: "eforkBotConfig" },
  });

  if (loading) {
    return <LoadingIndicator />;
  }

  if (error) {
    return <p>Error: {error.message}</p>;
  }

  const { eforkBotConfig } = data;
  if (!eforkBotConfig?.configPagesById) return;

  const steps = eforkBotConfig.configPagesById.fieldChatbotElements.map(
    (ChatBotStep) =>
      (() => {
        switch (ChatBotStep.entity.__typename) {
          case "ParagraphChatbotMessage":
            steps.push({
              id: ChatBotStep.entity.fieldChatbotId,
              message: ChatBotStep.entity.fieldText.value.replace(
                /<[^>]*>?/gm,
                ""
              ),
              trigger: ChatBotStep.entity.fieldTrigger,
            });
            break;
          case "ParagraphChatbotEnd":
            steps.push({
              id: ChatBotStep.entity.fieldChatbotId,
              message: ChatBotStep.entity.fieldText.value.replace(
                /<[^>]*>?/gm,
                ""
              ),
              end: true,
            });
            break;
          case "ParagraphChatbotOptions": {
            const options = [];

            ChatBotStep.entity.fieldOptions.map((option) => {
              options.push({
                value: option.entity.fieldLabel,
                label: option.entity.fieldLabel,
                trigger: option.entity.fieldTrigger,
              });
            });
            steps.push({
              id: ChatBotStep.entity.fieldChatbotId,
              options,
            });
            break;
          }

          case "ParagraphChatbotChooseAStyle": {
            const styles = [];

            ChatBotStep.entity.fieldStyles.map((style) => {
              styles.push({
                value: style.entity.fieldLabel,
                customTrigger: style.entity.fieldTrigger,
              });
            });
            steps.push({
              id: ChatBotStep.entity.fieldChatbotId,
              component: <ChooseAStyle value={styles} />,
            });
            break;
          }
          case "ParagraphChatbotLinks": {
            const links = [];

            ChatBotStep.entity.fieldChatbotLinks.map((link) => {
              links.push({
                url: link.entity.fieldTeaserbild,
                label: link.entity.fieldLabel,
                text: link.entity.fieldText,
                link: link.entity.fieldLink,
                customTrigger: link.entity.fieldTrigger,
              });
            });
            steps.push({
              id: ChatBotStep.entity.fieldChatbotId,
              component: <Links value={links} />,
              trigger: ChatBotStep.entity.fieldTrigger,
            });
            break;
          }
          case "ParagraphChatbotImage":
            steps.push({
              id: ChatBotStep.entity.fieldChatbotId,
              component: (
                <Image
                  text={ChatBotStep.entity.fieldText}
                  url={
                    ChatBotStep.entity.fieldBilderWCaption[0].entity
                      .fieldMediaImage.style.url
                  }
                  customTrigger={ChatBotStep.entity.fieldTrigger}
                />
              ),
              trigger: ChatBotStep.entity.fieldTrigger,
            });
            break;
          case "ParagraphChatbotAudio":
            steps.push({
              id: ChatBotStep.entity.fieldChatbotId,
              component: <Audio url={ChatBotStep.entity.fieldAudio} />,
              trigger: ChatBotStep.entity.fieldTrigger,
            });
            break;
          case "ParagraphChatbotVideo":
            steps.push({
              id: ChatBotStep.entity.fieldChatbotId,
              component: (
                <Video
                  text={ChatBotStep.entity.fieldText}
                  url={
                    ChatBotStep.entity.fieldVideo.entity.fieldMediaVideoFile
                      .entity.url
                  }
                  customTrigger={ChatBotStep.entity.fieldTrigger}
                />
              ),
              trigger: ChatBotStep.entity.fieldTrigger,
            });
            break;
          default:
            return null;
        }
      })()
  );

  return (
    <section className="eforkbot">
      <div className="container">
        <div className="row">
          <div className="col-16">
            {!!steps?.length && (
              <ChatBot
                steps={steps}
                botDelay={0}
                userDelay={500}
                customDelay={0}
                width={"100%"}
              />
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

Eforkbot.propTypes = {
  content: PropTypes.object.isRequired,
  eforkBotConfig: PropTypes.object,
};

export default Eforkbot;
