import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";

import LazyLoad from "react-lazyload";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";

import TeaserQueryCloudContent from "./queries/teaser-query-cloud-content.graphql";
import CloudDetailOverlay from "./components/cloud/cloud-detail-overlay";
import ListView from "./components/list/list-view";
import StoryContainer from "./components/cloud/story-container";
import { activeCloudDetailAction, viewModeAction } from "./cloud-view-action";
import LoadingIndicator from "../../../loading-indicator";
import { Close } from "@carbon/icons-react";
import { useQuery } from "@apollo/client";

const ContentCloud = () => {
  const container = useRef();

  const [viewMode, setViewMode] = useState("cloud");

  const activeCloudDetail = useSelector(
    (reduxStore) => reduxStore.contentCloud.activeCloudDetail
  );
  const dispatch = useDispatch();

  const toggleViewMode = (type) => {
    window.scrollTo(0, 0);
    setViewMode(type);
    dispatch(viewModeAction(type));
  };

  const scaleStoryElements = () => {
    setTimeout(() => {
      // @todo use ref.
      document
        .querySelectorAll(".cloud-teaser .teaser-wrapper")
        .forEach((item) => item.classList.add("scaled"));
    }, 1000);
  };

  const scrollListener = (elements) => {
    if (elements.length > 0) {
      const st = window.pageYOffset || document.documentElement.scrollTop;

      if (st >= window.innerHeight) {
        elements[0].classList.add("slide-in-first");
      } else {
        elements[0].classList.remove("slide-in-first");
      }

      if (st >= window.innerHeight * 1.5) {
        elements[0].classList.add("slide-in-second");
      } else {
        elements[0].classList.remove("slide-in-second");
      }
    }
  };

  useEffect(() => {
    const dialogElements = document.querySelectorAll(".dialogue-wrapper");
    const handleScroll = () => scrollListener(dialogElements);

    window.addEventListener("scroll", handleScroll);

    if (container.current != null) {
      /* Scale Items for Start*/
      scaleStoryElements();
    }

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const { data, loading, error } = useQuery(TeaserQueryCloudContent, {
    variables: {
      name: "nodes",
    },
  });

  if (loading) {
    return <LoadingIndicator />;
  }

  if (error) {
    console.log(error);
  }

  let entities = null;

  if (data.nodeQuery && !loading && !error) {
    entities = data.nodeQuery.entities;
  }

  let amountPerWrapper = 5;

  return (
    <div className="paragraph paragraph-content-cloud">
      <div className="content-wrap">
        <div className="header">
          <div className="wrapper">
            <div className="function" id="header-function">
              <div className="view-chooser item">
                <span
                  className={classNames({
                    "cloud-icon": true,
                    active: viewMode === "cloud",
                  })}
                  onClick={() => toggleViewMode("cloud")}
                >
                  cloud
                </span>
                <span
                  className={classNames({
                    "list-icon": true,
                    active: viewMode === "list",
                  })}
                  onClick={() => toggleViewMode("list")}
                >
                  list
                </span>
              </div>
            </div>
          </div>
        </div>

        {viewMode === "cloud" ? (
          <div
            ref={container}
            className={`container cloud-view ${
              activeCloudDetail ? "detail-opened" : ""
            }`}
            id="cloud-view-draggable"
          >
            <div className="cloud-detail-scroll-container">
              {activeCloudDetail && (
                <CloudDetailOverlay content={activeCloudDetail} />
              )}
            </div>
            <StoryContainer
              entities={entities.slice(0, amountPerWrapper)}
              scaleStoryElements={scaleStoryElements}
            />
            <>
              {[...Array(Math.ceil(entities.length / amountPerWrapper))].map(
                (time, i) => {
                  return (
                    <LazyLoad key={i} offset={100}>
                      <StoryContainer
                        entities={entities.slice(
                          (i + 1) * amountPerWrapper,
                          (i + 2) * amountPerWrapper
                        )}
                        scaleStoryElements={scaleStoryElements}
                      />
                    </LazyLoad>
                  );
                }
              )}
            </>
          </div>
        ) : (
          <>
            <div className="list-view">
              <ListView entities={entities} />
              {activeCloudDetail && (
                <div
                  className="cloud-detail-go-back"
                  onClick={() => dispatch(activeCloudDetailAction(null))}
                  style={{ opacity: 1, zIndex: 150, pointerEvents: "all" }}
                >
                  <Close size={32} color="black" />
                </div>
              )}
            </div>
            <div className="cloud-detail-scroll-container">
              {activeCloudDetail && (
                <CloudDetailOverlay content={activeCloudDetail} />
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export const ContentCloudNodePropTypes = {
  entityUrl: PropTypes.shape({
    path: PropTypes.string,
  }),
  entityLabel: PropTypes.string,
  fieldTeasertext: PropTypes.shape({
    value: PropTypes.string,
  }),
  fieldBild: PropTypes.shape({
    entity: PropTypes.shape({
      fieldMediaImage: PropTypes.shape({
        alt: PropTypes.string,
        style: PropTypes.shape({
          url: PropTypes.string,
        }),
      }),
    }),
  }),
  fieldModules: PropTypes.array,
};

ContentCloud.propTypes = {
  activeCloudDetail: PropTypes.shape(ContentCloudNodePropTypes),
  nodes: PropTypes.object.isRequired,
  content: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
};

export default ContentCloud;
