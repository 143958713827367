import React, { useState } from "react";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/client";
import SubmissionsQuery from "./submissions.graphql";
import toast from "@general-components/ui/toast";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import moment from "moment";
import _ from "lodash";

const CardSubmissionSkeleton = () => (
  <div className="list-group list-group-flush">
    <a className="list-group-item placeholder-glow">
      <div className="d-flex justify-content-between align-items-center">
        <span className="placeholder w-25"></span>
        <span className="placeholder col-3"></span>
      </div>
    </a>

    <a className="list-group-item placeholder-glow">
      <div className="d-flex justify-content-between align-items-center">
        <span className="placeholder w-50"></span>
        <span className="placeholder col-3"></span>
      </div>
    </a>

    <a className="list-group-item placeholder-glow">
      <div className="d-flex justify-content-between align-items-center">
        <span className="placeholder col-6"></span>
        <span className="placeholder col-3"></span>
      </div>
    </a>
  </div>
);

const CardSubmissions = ({ view }) => {
  const [page, setPage] = useState(0);

  const { data, loading, error } = useQuery(SubmissionsQuery, {
    variables: {
      view,
      page,
    },
    fetchPolicy: "network-only",
  });

  if (error) {
    toast({
      message: "Fehler beim Laden der Daten [submission_query].",
      type: "error",
    });
    return <div>Error: {error.message}</div>;
  }

  const viewData = data?.entityById?.executable || {};
  const totalRows = viewData?.execute?.total_rows || 0;
  const perPage = viewData?.pager?.perPage || 5;

  console.log(totalRows, (page + 1) * perPage);

  // extract submissions from data.
  let submissions = data?.entityById?.executable?.execute?.rows || [];

  // get the last submission of each submission since this is the latest one.
  submissions = submissions.map((submission) =>
    submission.fieldWebformSubmissionId.at(-1)
  );

  // remove null values.
  submissions = _.compact(submissions);

  // parse encoded data and store as object in submissions so we can use it.
  submissions = submissions.map((submission) => ({
    ...submission,
    data: JSON.parse(submission.encodedData),
  }));

  return (
    <div className="card">
      <div className="card-header d-flex justify-content-between">
        <h3>
          <FormattedMessage id={`submissions.header.${view}`} />
        </h3>
        <Link className="btn btn-primary" to="/intern/submit-event">
          Termin Einreichen
        </Link>
      </div>
      {loading && <CardSubmissionSkeleton />}
      {!loading && !error && !!submissions?.length && (
        <div className="list-group list-group-flush">
          {submissions.map((submission) => (
            <Link
              className="list-group-item list-group-item-action"
              to={`/intern/submit-event/${submission.uuid}`}
              key={submission.uuid}
            >
              <div className="d-flex justify-content-between align-items-center">
                <span>{submission.data.title}</span>
                <small>
                  Eingereicht am{" "}
                  {moment(submission.created)
                    .local()
                    .format("dd, DD.MM.YY, HH:mm")}
                </small>
              </div>
            </Link>
          ))}
        </div>
      )}
      {!loading && !error && !submissions?.length && (
        <div className="card-body">
          <p>Keine Einträge vorhanden.</p>
          <Link className="btn btn-primary" to="/intern/submit-event">
            Termin Einreichen
          </Link>
        </div>
      )}
      {totalRows > perPage && (
        <div className="card-footer">
          <div className="d-flex justify-content-between">
            <button
              className="btn btn-outline-primary btn-sm"
              onClick={() => setPage(page - 1)}
              disabled={page === 0}
            >
              <FormattedMessage id="pagination.previous" />
            </button>
            <button
              className="btn btn-outline-primary btn-sm"
              onClick={() => setPage(page + 1)}
              disabled={
                !submissions?.length || totalRows < (page + 1) * perPage
              }
            >
              <FormattedMessage id="pagination.next" />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

CardSubmissions.propTypes = {
  view: PropTypes.oneOf(["event_submissions"]).isRequired,
};

export default CardSubmissions;
