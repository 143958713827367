import React from "react";
import { useSelector } from "react-redux";

import eventPageQuery from "./event.graphql";
import { useLocation } from "react-router-dom";
import useNodeQuery from "@hooks/use-node-query";
import NodeWrapper from "@nodes/components/node-wrapper";
import PagerFullPage from "@js/general-components/pager-full-page";
import ContentBase from "@general-components/content-base/content-base";
import Share from "@general-components/static-modules/share/share";
import Related from "@general-components/static-modules/related/related";
import ErrorBoundary from "@js/error-boundary";

const Event = () => {
  /**
   * Hook to get information from Redux Store
   */
  const pagerConfig = useSelector(
    (reduxStore) => reduxStore.appStore.pagerFullPages
  );
  const location = useLocation();
  const queryStrings = location.search;

  const { entity, loading, error } = useNodeQuery(eventPageQuery);

  // Add event-infos per default.
  const modules = [
    {
      entity: {
        entityBundle: "event_information",
      },
    },
    ...(entity?.fieldModules.list || []),
  ];

  return (
    <NodeWrapper entity={entity} loading={loading} error={error}>
      <ContentBase content={modules} nodeContent={entity} />

      <Share nodeContent={entity} />

      <ErrorBoundary>
        <Related nodeContent={entity} contentType={["event"]} />
      </ErrorBoundary>

      {queryStrings.p &&
        pagerConfig.filter((config) => config.id === queryStrings.p).length >
          0 && (
          <PagerFullPage
            config={
              pagerConfig.filter((config) => config.id === queryStrings.p)[0]
            }
            currentId={entity.entityId}
          />
        )}
    </NodeWrapper>
  );
};

Event.propTypes = {};

export default Event;
